<template>
  <v-container id="IotEdgeCreateView" class="px-8" fluid>
    <v-row>
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div>
            <div class="text-h5 font-weight-bold">
              {{
                breadcrumbs[1].text ? breadcrumbs[1].text : "IoT Edge Detail"
              }}
              <v-chip v-if="isEnableHaMode" small color="primary" class="px-6">
                <span class="text-caption"> High Availability</span>
              </v-chip>
            </div>
            <v-breadcrumbs
              class="px-0 py-2"
              :items="breadcrumbs"
              divider="/"
            ></v-breadcrumbs>
          </div>
          <v-btn
            small
            depressed
            color="primary"
            disabled
            @click.prevent="createNewIotEdgeBtnClicked"
          >
            Save
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Site Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Site Information</span
        >
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          filled
          disabled
          label="Site Name"
          :value="site.siteName"
        />
      </v-col>
      <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
    </v-row>

    <!-- Iot Edge Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Iot Edge Information</span
        >
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          clearable
          label="IoT Edge Name"
          placeholder="IoT Edge Name"
          v-model="iotEdgeName"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          dense
          outlined
          :filled="!isEnableHaMode"
          :disabled="!isEnableHaMode"
          clearable
          persistent-hint
          label="Stand by IoT Edge Name"
          placeholder="Stand by IoT Edge Name"
          hint="*High Availability mode should be enable"
          v-model="iotStandbyName"
        />
      </v-col>
      <v-col cols="4">
        <v-checkbox
          class="mt-1"
          hide-details
          label="Enable High Availability Mode ( HA )"
          v-model="isEnableHaMode"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" class="pb-0"> <v-divider></v-divider> </v-col>
    </v-row>

    <!-- Module Information -->
    <v-row>
      <v-col cols="12" class="pb-0">
        <span class="text-subtitle-1 font-weight-medium">
          Module Information</span
        >
      </v-col>
    </v-row>
    <CreateEdgeModuleCell
      v-for="(edge_module, index) in edge_modules"
      :key="index"
      :sequence="index + 1"
      :edit_mode="true"
      :site_id="site._id"
      :name="edge_module.name"
      :type="edge_module.type"
      :architect="edge_module.architect"
      :version="edge_module.version"
      :registry="edge_module.registry"
      :serialPort="edge_module.serialPort"
      :deviceIds="edge_module.deviceIds"
      :enableLogging="edge_module.enableLogging"
    />
    <!-- New Module Button -->
    <v-row>
      <v-col cols="12" class="d-flex justify-center my-4">
        <v-btn
          small
          depressed
          outlined
          color="primary"
          @click="appendCreateModuleObject"
        >
          <v-icon left> mdi-plus </v-icon>
          ADD MORE MODULE
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import CreateEdgeModuleCell from "@/components/cells/CreateEdgeModuleCell";

export default {
  name: "IotEdgeCreateView",
  components: {
    CreateEdgeModuleCell,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "IoT Edges",
          exact: true,
          disabled: false,
          to: { name: "edge" },
        },
        {
          text: "Create New IoT Edge",
          exact: true,
          disabled: true,
          to: { name: "edge-create" },
        },
      ],
      site: {
        _id: null,
        siteName: null,
      },
      iotEdgeName: null,
      iotStandbyName: null,
      isEnableHaMode: false,
    };
  },
  computed: {
    ...mapState({
      edge_modules: (state) => state.edge.edge_modules,
    }),
  },
  methods: {
    ...mapActions({
      requestGetIotEdgeDetailByID: "requestGetIotEdgeDetailByID",
    }),
    ...mapMutations({
      appendCreateModuleObject: "APPEND_CREATE_MODULE_OBJECT",
    }),
  },
  async beforeMount() {
    // Call API : get current device detail by id
    const response = await this.requestGetIotEdgeDetailByID(
      this.$route.params.id
    );

    this.site = response.siteId;
    this.iotEdgeName = response.edgeName;
    this.isEnableHaMode = response.enabledHA;
    if (response.enabledHA) {
      this.iotStandbyName = response.edgeStandbyName;
    }
    // Assign breadcrumbs text
    this.breadcrumbs[1].text = response.edgeName;
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>
